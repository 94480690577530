import { NavLink } from "react-router-dom";
import "../../css/buttons.css";
import { motion } from "framer-motion";

function SocialCercle(props) {
  return (
    <div className={"flexRow " }>
        <a href="https://www.facebook.com/pages/Cafe-Alma-%D7%A7%D7%A4%D7%94-%D7%A2%D7%9C%D7%9E%D7%94/585239098563723?locale=he_IL" className={"cercleSocial flexCol center "+ props.class}><li className="fa fa-facebook"></li></a>
        <a href="https://www.instagram.com/almayafo/" className={"cercleSocial flexCol center "+ props.class}><li className="fa fa-instagram"></li></a>
        <a href="" className={"cercleSocial flexCol center "+ props.class}><li className="fa fa-twitter"></li></a>

        <a href="https://wa.me/+972539650248" className={"cercleSocial flexCol center "+ props.class}><li className="fa fa-whatsapp"></li></a>

    </div>
  );
}

export default SocialCercle;
